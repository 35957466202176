import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import {
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
  catchError,
  distinctUntilChanged,
} from 'rxjs/operators'
import userflow from 'userflow.js'
import { State as AppStatusState } from 'src/app/app-status/app-status.state'
import { selectReturnUrl } from 'src/app/app-status/selectors/app-status.selectors'
import { AccountStateEnum } from 'src/app/enums/account.enums'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'
import { IAccount } from '../models/iaccount'
import { State as AccountState } from './../account.state'
import * as accountActions from './../actions/account.actions'
import * as accountSelectors from './../selectors/account.selectors'
import { AccountService } from './../services/account.service'
import { AccountActionTypes } from './../actions/account.actions'
import { NotificationService } from 'src/app/notification/services/notification.service'
import * as billingActions from 'src/app/manage-account/actions/billing.action'
import * as fromLocation from 'src/app/location/location.store'
import * as locationActions from 'src/app/location/actions/location.actions'
import * as usStateActions from 'src/app/us-states/actions/us-states.actions'
import { AttestationService } from 'src/app/attestation/services/attestation.service'
import * as setupSelector from 'src/app/setup/selectors/setup.selectors'
import { State as SetupStore } from 'src/app/setup/setup.state'
import * as setupActions from 'src/app/setup/actions/setup.actions'
import { MessageService } from 'primeng/api'
import { BOTTOM_TOAST_KEY } from 'src/app/public/shell-public-basic/shell-public-basic.component'
import { State as BillingState } from 'src/app/manage-account/manage-account.state'

@Injectable()
export class AccountEffects {
  constructor(
    private locationStore: Store<fromLocation.State>,
    private actions$: Actions,
    private accountService: AccountService,
    private attestationService: AttestationService,
    private appStatusStore: Store<AppStatusState>,
    private accountStore: Store<AccountState>,
    private router: Router,
    private notificationService: NotificationService,
    private setupStore: Store<SetupStore>,
    private messageService: MessageService,
    private billingStore: Store<BillingState>
  ) {}

  getAccountData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.RequestAccountData>(AccountActionTypes.RequestAccountData),
      withLatestFrom(this.accountStore.select(accountSelectors.selectDataSource)),
      filter(([_, source]) => source !== DataSourceEnum.API),
      switchMap(() =>
        this.accountService.getAccount().pipe(
          tap((_) => {
            this.billingStore.dispatch(
              new billingActions.InitSubscription({ suppress404Error: true })
            )
          }),
          map((account) => new accountActions.GetAccountDataSuccess({ account }))
          // catchError((error) => of(new accountActions.GetAccountDataFailure()))
        )
      )
    )
  )

  getAccountDataAfterLogin$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.RequestAccountDataLogin>(AccountActionTypes.RequestAccountDataLogin),
      switchMap(() =>
        this.accountService.getAccount().pipe(
          map((account) => new accountActions.GetAccountDataSuccessRedirect({ account }))
          // catchError((error) => of(new accountActions.GetAccountDataFailure()))
        )
      )
    )
  )

  refreshAccountData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.RefreshAccountData>(AccountActionTypes.RefreshAccountData),
      switchMap((action) =>
        this.accountService
          .refreshAccount()
          .pipe(map((account) => new accountActions.GetAccountDataSuccess({ account })))
      )
    )
  )

  refreshAccountDataForAutologin$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.GetAccountForAutoLogin>(AccountActionTypes.GetAccountForAutoLogin),
      switchMap((action) =>
        this.accountService
          .refreshAccount()
          .pipe(map((account) => new accountActions.GetAccountForAutoLoginSuccess({ account })))
      )
    )
  )

  initAccountSuccessWithRedirect$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<accountActions.GetAccountDataSuccessRedirect>(
          AccountActionTypes.GetAccountDataSuccessRedirect
        ),
        tap((_) => this.locationStore.dispatch(new locationActions.InitLocations())),
        withLatestFrom(
          this.appStatusStore.select(selectReturnUrl),
          this.setupStore.select(setupSelector.selectIsIntegrationsOnboarding)
        ),
        tap(([action, { pathname, queryParams }, isIntegrationsOnboarding]) => {
          if (isIntegrationsOnboarding) {
            this.messageService.clear(BOTTOM_TOAST_KEY)
            this.setupStore.dispatch(
              new setupActions.SetIsIntegrationsOnboarding({ isIntegrationsOnboarding: false })
            )
          }
          const account: IAccount = action.payload.account
          const conversion =
            account.conversionStatus &&
            account.conversionStatus.hasCompleteProfile &&
            account.conversionStatus.hasLocation

          switch (account.accountState) {
            case AccountStateEnum.Prospective: {
              if (conversion) {
                isIntegrationsOnboarding
                  ? this.router.navigate(['/go/integrations'])
                  : this.router.navigate(['/go/dashboard'])
              } else {
                this.router.navigate(['complete-account'])
              }
              return
            }
            case AccountStateEnum.Converted: {
              if (
                pathname.indexOf('/get-started') === 0 ||
                pathname.indexOf('/complete-account') === 0
              ) {
                isIntegrationsOnboarding
                  ? this.router.navigate(['/go/integrations'])
                  : this.router.navigate(['/go/dashboard'])
                return
              }
              if (pathname.indexOf('/go/') === 0) {
                this.router.navigate([pathname], { queryParams })
                return
              }
              isIntegrationsOnboarding
                ? this.router.navigate(['/go/integrations'])
                : this.router.navigate(['/go/dashboard'])
              break
            }
            case AccountStateEnum.Attestation: {
              if (pathname.indexOf('states-management/direct-file-manager') > 0) {
                this.router.navigate([pathname])
              }
            }
          }
        }),
        tap((_) =>
          this.billingStore.dispatch(
            new billingActions.InitSubscription({ suppress404Error: true })
          )
        )
      ),
    { dispatch: false }
  )

  accountSuccessAutoRedirect$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<accountActions.GetAccountForAutoLoginSuccess>(
          AccountActionTypes.GetAccountForAutoLoginSuccess
        ),
        tap((action) => {
          const account: IAccount = action.payload.account
          const conversion =
            account.conversionStatus &&
            account.conversionStatus.hasCompleteProfile &&
            account.conversionStatus.hasLocation

          switch (account.accountState) {
            case AccountStateEnum.Prospective: {
              if (conversion) {
                this.router.navigate(['/go/dashboard'])
              } else {
                this.router.navigate(['complete-account'])
              }
              return
            }
            case AccountStateEnum.Converted: {
              this.router.navigate(['/go/dashboard'])
              return
            }
            default: {
              this.router.navigate(['/go/dashboard'])
            }
          }
        })
      ),
    { dispatch: false }
  )

  updateConversionStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.InitUpDateConversionStatus>(
        AccountActionTypes.InitUpDateConversionStatus
      ),
      withLatestFrom(this.accountStore.select(accountSelectors.selectMerchantId)),
      filter(([action, merchantId]) => !!merchantId),
      map(([action, merchantId]) => merchantId),
      switchMap((action) =>
        this.accountService
          .getGoLiveStatus()
          .pipe(
            map(
              (conversionStatus) => new accountActions.UpdateConversionStatus({ conversionStatus })
            )
          )
      )
    )
  )

  saveAccountState$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<
        accountActions.UpdateAccountState | accountActions.UpdateAccountStateFromMerchantStore
      >(
        AccountActionTypes.UpdateAccountState,
        AccountActionTypes.UpdateAccountStateFromMerchantStore
      ),
      switchMap((action) =>
        this.accountService
          .saveAccountState(action.payload.state)
          .pipe(map(() => new accountActions.SaveAccountStateSuccess()))
      )
    )
  )

  // TODO: Why do we need two separate actions/effects for UpdateProfileFromAccount vs. UpdateProfileFromNewUser
  updateProfileFromNewUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.UpdateProfileFromNewUser>(AccountActionTypes.UpdateProfileFromNewUser),
      switchMap((action) =>
        this.accountService.updateAccountProfile(action.payload.profile).pipe(
          tap((profile) => {
            if (!!profile.ein) {
              userflow.track('account_updated')
            }
          }),
          map(() => new accountActions.RefreshAccountData()),
          tap(() => this.notificationService.showSuccess({ title: 'Saved account profile' }))
        )
      )
    )
  )

  // TODO: Why do we need two separate actions/effects for UpdateProfileFromAccount vs. UpdateProfileFromNewUser
  updateProfileFromUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.UpdateProfileFromAccount>(AccountActionTypes.UpdateProfileFromAccount),
      switchMap((action) =>
        this.accountService.updateAccountProfile(action.payload.profile).pipe(
          tap((profile) => {
            if (!!profile.ein) {
              userflow.track('account_updated')
            }
          }),
          map(() => new accountActions.RefreshAccountData()),
          tap(() => this.notificationService.showSuccess({ title: 'Saved account profile' }))
        )
      )
    )
  )

  cancelAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.CancelAccount>(AccountActionTypes.CancelAccount),
      withLatestFrom(this.accountStore.select(accountSelectors.selectMerchantId)),
      switchMap(([action, merchantId]) =>
        this.accountService
          .cancelAccount(merchantId, action.payload.unregisterSST, action.payload.questionnaire)
          .pipe(map((account) => new accountActions.CancelAccountSuccess({ account })))
      )
    )
  )

  reactivateAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.ReactivateAccount>(AccountActionTypes.ReactivateAccount),
      withLatestFrom(this.accountStore.select(accountSelectors.selectMerchantId)),
      switchMap(([action, merchantId]) =>
        this.accountService
          .reActivateAccount(merchantId)
          .pipe(map((account) => new accountActions.ReactivateAcountSuccess({ account })))
      )
    )
  )

  clearAccountData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.ClearAccountData>(AccountActionTypes.ClearAccountData),
      switchMap((action) =>
        this.accountService
          .clearLocalSavedAccountData()
          .pipe(map(() => new accountActions.ClearAccountDataSuccess()))
      )
    )
  )

  turnOnAutomatedCompliance$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.TurnOnAutomatedCompliance>(
        AccountActionTypes.TurnOnAutomatedCompliance
      ),
      switchMap((action) =>
        this.accountService
          .turnOnAutomatedCompliance(action.payload.billingFrequency)
          .pipe(map((account) => new accountActions.UpdateServicePlanSuccess({ account })))
      )
    )
  )

  turnOnSelectedCompliance$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.TurnOnSelectiveCompliance>(
        AccountActionTypes.TurnOnSelectiveCompliance
      ),
      switchMap((action) =>
        this.accountService
          .turnOnSelectiveCompliance(action.payload.unregisterSST)
          .pipe(map((account) => new accountActions.UpdateServicePlanSuccess({ account })))
      )
    )
  )

  turnOnGrandfatheredSelectedCompliance$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.TurnOnGrandfatheredSelectiveCompliance>(
        AccountActionTypes.TurnOnGrandfatheredSelectiveCompliance
      ),
      switchMap((action) =>
        this.accountService
          .turnOnGrandfatheredSelectedCompliance(
            action.payload.billingFrequency,
            action.payload.servicePlan
          )
          .pipe(map((account) => new accountActions.UpdateServicePlanSuccess({ account })))
      )
    )
  )

  addPrepayForSelectPlan$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.AddPrepay>(AccountActionTypes.AddPrepay),
      switchMap((action) =>
        this.accountService
          .addToPrepayServiceAccount(action.payload.amount)
          .pipe(map((account) => new accountActions.AddPrepaySuccess({ account })))
      )
    )
  )

  updateIndustryClassification$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.UpdateIndustryClassification>(
        AccountActionTypes.UpdateIndustryClassification
      ),
      switchMap((action) =>
        this.accountService
          .updateIndustryClassification(action.payload.code)
          .pipe(
            map((account) => new accountActions.UpdateIndustryClassificationSuccess({ account }))
          )
      )
    )
  )

  updateIndustryClassificationSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<accountActions.UpdateIndustryClassificationSuccess>(
          AccountActionTypes.UpdateIndustryClassificationSuccess
        ),
        switchMap((action) => {
          this.notificationService.showSuccess({
            title: 'Success',
            description: 'Changes Saved',
          })
          this.router.navigate([this.router.url]).then(() => {
            window.location.reload()
          })
          return of()
        })
      ),
    { dispatch: false }
  )

  refreshStates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.UpdateServicePlanSuccess>(AccountActionTypes.UpdateServicePlanSuccess),
      map(() => {
        return new usStateActions.RefreshUsStates()
      })
    )
  )

  extendConfigWindow$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.ExtendConfigurationWindow>(
        AccountActionTypes.ExtendConfigurationWindow
      ),
      // withLatestFrom(this.accountStore.select(accountSelectors.selectMerchantId)),
      switchMap((action) =>
        this.accountService.extendConfigurationWindow(action.payload.paymentMethod).pipe(
          map(() => new accountActions.RefreshAccountData()),
          catchError((error: Error) => {
            return of(new accountActions.ExtendConfigurationWindowFailed({ error: error.message }))
          })
        )
      )
    )
  )

  submitPaAttestation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.SubmitPaAttestation>(AccountActionTypes.SubmitPaAttestation),
      switchMap((action) =>
        this.attestationService
          .submitPaAttestation(action.payload.form)
          .pipe(map(() => new accountActions.RefreshAccountData()))
      )
    )
  )

  associateStoreInfo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<accountActions.AssociateStoreInfo>(AccountActionTypes.AssociateStoreInfo),
      switchMap((action) =>
        this.accountService.associatestoreinfo(action.payload.storeInfoGuid).pipe(
          map(() => new accountActions.AssociateStoreInfoSuccess({})),
          catchError((error) => of(new accountActions.AssociateStoreInfoFailure({ error })))
        )
      )
    )
  )
}
